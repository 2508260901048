/**
 * Validates the form fields and enables/disables the submit button based on the form's validity.
 *
 * @param {HTMLFormElement} form - The memory form element.
 * @param {Boolean} photoExists
 */
function validateFields(form, photoExists) {
  if (!form) return;

  const contentInput = form.querySelector("textarea");
  const hiddenInput = form.querySelector("#tribute_memory_hidden");
  const inputs = form.querySelectorAll("input, select");

  setTimeout(() => {
    let isAttachmentFilled = Array.from(form.querySelectorAll("input[type='file']")).some(
      (input) => input.files.length > 0
    );
    let isContentFilled = contentInput?.value.trim() !== "";

    inputs.forEach((input) => {
      if (input.validity.patternMismatch) {
        input.setCustomValidity(
          "Please use normal characters (letters, apostrophe's and hyphens only)."
        );
      } else if (input.value.length > 100) {
        input.setCustomValidity("This field cannot exceed 100 characters.");
      }
    });

    if (hiddenInput) {
      hiddenInput.value = !photoExists && !isAttachmentFilled && !isContentFilled;
    }

    _toggleSubmitButton(form);
  }, 100);
}

/**
 * Resets the form to its initial state, clearing non-essential fields,
 * toggling visibility of the form and thank you message, and resetting button states.
 *
 * @param {HTMLFormElement} form - The memory form element.
 * @param {HTMLElement} formContainer - The form container containing the form.
 * @param {HTMLElement} thankYouMessage - The thank you message element.
 */
function resetModal(form, formContainer, thankYouMessage) {
  formContainer.classList.remove("hidden");
  thankYouMessage.classList.add("hidden");

  const inputs = form.querySelectorAll("textarea, input, select");
  inputs.forEach((input) => {
    if (
      ![
        "tribute_memory[first_name]",
        "tribute_memory[last_name]",
        "tribute_memory[email]",
        "tribute_memory[uploaded_by_nok]",
      ].includes(input.name)
    ) {
      input.value = "";
    }
  });

  _toggleSubmitButton(form);
  toggleButtonLoading(form, false);
}

/**
 * Redirects to the next memory or the tribute URL, depending on the form's configuration.
 * It will redirect to the next memory form or the redirect URL.
 * If the memorial type is DIY, the form will be submitted to the tribute URL.
 *
 * @param {HTMLFormElement} form - The memory form element.
 */
async function redirectOrPublish(form) {
  const { tributeUrl, redirectUrl, memorialType } = form.dataset;
  const nextMemory = _getNextMemoryId();
  if (nextMemory) {
    window.location.href = `${redirectUrl}?selectedMemory=${nextMemory}`;
  } else if (memorialType !== "DIY") {
    window.location.href = tributeUrl;
  } else {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content");
    const response = await fetch(tributeUrl, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });
    if (response.ok) {
      window.location.href = response.url;
    }
  }
}

/**
 * Makes sure the file input field is not included in the form submission when it is not visible.
 *
 * @param {HTMLElement} fileInputField - The container element of the file input field.
 */
function toggleFileInputVisibility(fileInputField) {
  const fileInput = fileInputField.querySelector("input[type='file']");
  const hiddenInput = fileInputField.querySelector("input[type='hidden']");
  if (fileInputField.classList.contains("hidden")) {
    fileInput.removeAttribute("name");
    if (hiddenInput) {
      hiddenInput.remove();
    }
  }
}

/**
 * Toggles a loading state on the submit button, showing or hiding a spinner and changing button text.
 *
 * @param {HTMLFormElement} form - The memory form element.
 * @param {Boolean} disable - Whether to disable the submit button.
 */
function toggleButtonLoading(form, disable) {
  const submitButton = form.querySelector("button[type='submit']");
  const spinnerElement = submitButton.querySelector("[name='spinner']");

  if (disable) {
    submitButton.disabled = true;
  } else {
    spinnerElement.classList.add("hidden");
  }

  const buttonTextElement = submitButton.querySelector("[name='button-text']");

  if (buttonTextElement.textContent === "Saving") {
    buttonTextElement.textContent =
      form.dataset.actionType === "edit" ? "Save Memory" : "Share Memory";
  } else {
    buttonTextElement.textContent = "Saving";
  }
}

/**
 * Toggles the submit button based on the validity of the form.
 *
 * @param {HTMLFormElement} form - The memory form element.
 */
function _toggleSubmitButton(form) {
  const submitButton = form.querySelector("button[type='submit']");
  submitButton.disabled = !form.checkValidity();
}

/**
 * Retrieves the next memory ID from the URL query parameters.
 * This ID is used to determine the next item in a sequence of memories, retrieved from local storage.
 *
 * @returns {string|boolean} - The next memory ID, or false if none exists.
 */
function _getNextMemoryId() {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const selectedMemory = urlParams.get("selectedMemory");
    const selectedMemories = JSON.parse(localStorage.getItem("selectedMemories")) || [];
    return selectedMemories[selectedMemories.indexOf(selectedMemory) + 1] || false;
  } catch {
    return false;
  }
}

export {
  toggleFileInputVisibility,
  toggleButtonLoading,
  redirectOrPublish,
  validateFields,
  resetModal,
};
