import { Controller } from "@hotwired/stimulus";
import {
  validateFields,
  redirectOrPublish,
  toggleFileInputVisibility,
  toggleButtonLoading,
  resetModal,
} from "./memory-utilities";

export default class extends Controller {
  static targets = [
    "form",
    "formContainer",
    "carousel",
    "thankYouMessage",
    "fileInputField",
    "fileInput",
    "deleteImageButton",
  ];

  connect() {
    this.photoExists = this.formTarget.dataset.photoExists == "true";
    validateFields(this.formTarget, this.photoExists);
    this.addEventListeners();

    if (this.hasCarouselTarget) {
      toggleFileInputVisibility(this.fileInputFieldTarget);
    }
  }

  addEventListeners() {
    document.addEventListener("file_upload:change", this.handleFileUpload.bind(this));
    this.formTarget.addEventListener("input", this.handleInputChange.bind(this));
  }

  handleFileUpload(_event) {
    validateFields(this.formTarget, this.photoExists);
  }

  handleInputChange(_event) {
    validateFields(this.formTarget, this.photoExists);
  }

  async submit(event) {
    event.preventDefault();

    if (!this.formTarget.checkValidity()) {
      this.formTarget.reportValidity();
      return;
    }

    toggleButtonLoading(this.formTarget, true);

    const formData = new FormData(this.formTarget);
    if (this.formTarget.dataset.actionType === "edit") {
      formData.append("_method", "PATCH");
    }
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content");
    const response = await fetch(this.formTarget.action, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData,
    });

    const data = await response.json();

    if (response.ok && data.status === "success") {
      if (this.hasFormContainerTarget) {
        this.formContainerTarget.classList.add("hidden");
        this.thankYouMessageTarget.classList.remove("hidden");
      } else {
        redirectOrPublish(this.formTarget);
      }
    }
  }

  skip() {
    redirectOrPublish(this.formTarget);
  }

  previous() {
    window.history.back();
  }

  async removeFile(event) {
    event.preventDefault();

    const carouselBody = this.carouselTarget.querySelector(".hs-carousel-body");
    const indexToRemove = carouselBody.dataset.index;
    const carouselImages = Array.from(carouselBody.querySelectorAll("img"));

    if (carouselImages.length === 1) {
      this.photoExists = false;
      this.toggleFileInput();
    }

    const imageToRemoveId = carouselImages[indexToRemove].id;

    let formData = new FormData();
    formData.append("_method", "PATCH");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
    const response = await fetch(this.deleteImageButtonTarget.dataset.url + "/" + imageToRemoveId, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      body: formData,
    });

    if (response.ok) {
      const customEvent = new CustomEvent("carousel:ImageRemoved", {
        detail: { id: imageToRemoveId },
      });

      this.carouselTarget.querySelector("#carousel").dispatchEvent(customEvent);
    }
  }

  addAnotherMemory() {
    resetModal(this.formTarget, this.formContainerTarget, this.thankYouMessageTarget);

    // Reset the file component as well.
    this.resetFiles();
  }

  toggleFileInput() {
    const fileInput = this.fileInputFieldTarget.querySelector("input[type='file']");
    this.fileInputFieldTarget.classList.remove("hidden");
    fileInput.setAttribute("name", "tribute_memory[append_memory_attachments][]");
    this.carouselTarget.classList.add("hidden");
  }

  resetFiles() {
    const fileUploadController = this.application.getControllerForElementAndIdentifier(
      this.element.querySelector('[data-controller="file_upload_input"]'),
      "file_upload_input"
    );

    if (fileUploadController) {
      fileUploadController.removeAllFiles();
    }
  }
}
